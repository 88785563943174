import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle } from "vue"
import _imports_0 from '@/assets/icons/zan.svg'
import _imports_1 from '@/assets/icons/zan-fill.svg'
import _imports_2 from '@/assets/images/index/Rectangle_bottom.png'


const _hoisted_1 = { id: "app" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "banner-container" }
const _hoisted_4 = { class: "banner-text-container" }
const _hoisted_5 = { class: "banner-title" }
const _hoisted_6 = { class: "user-info-container" }
const _hoisted_7 = { class: "user-name" }
const _hoisted_8 = { class: "mail-address" }
const _hoisted_9 = { class: "mail-address" }
const _hoisted_10 = { class: "info-value" }
const _hoisted_11 = {
  key: 0,
  class: "grey-container"
}
const _hoisted_12 = {
  class: "my-ideas",
  style: {"text-align":"left"}
}
const _hoisted_13 = { class: "comment-bar" }
const _hoisted_14 = ["fill"]
const _hoisted_15 = ["fill"]
const _hoisted_16 = { class: "idea-text" }
const _hoisted_17 = { class: "idea-title" }
const _hoisted_18 = { class: "comment-content" }
const _hoisted_19 = { class: "comment-detail" }
const _hoisted_20 = {
  class: "comment-content-lastbar",
  style: {"margin-top":"0.5rem"}
}
const _hoisted_21 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_22 = { class: "statusAndTime" }
const _hoisted_23 = ["onClick"]
const _hoisted_24 = {
  src: _imports_0,
  class: "icon"
}
const _hoisted_25 = {
  src: _imports_1,
  class: "icon"
}
const _hoisted_26 = {
  class: "icon-text",
  style: {"margin-left":"4px"}
}
const _hoisted_27 = { style: {"width":"100%","padding-top":"0.5rem","padding-bottom":"0.5rem"} }
const _hoisted_28 = ["onClick"]
const _hoisted_29 = {
  key: 1,
  class: "grey-container"
}
const _hoisted_30 = { style: {"margin-top":"2rem"} }
const _hoisted_31 = {
  key: 2,
  class: "grey-container"
}
const _hoisted_32 = { style: {"display":"flex","justify-content":"space-between","align-items":"center"} }
const _hoisted_33 = { class: "my-ideas" }
const _hoisted_34 = ["onClick"]
const _hoisted_35 = ["src"]
const _hoisted_36 = { style: {"width":"100%","padding-left":"1rem","padding-bottom":"1.5rem","border-bottom":"1px solid rgba(211, 211, 211, 0.5)"} }
const _hoisted_37 = { class: "title-bar" }
const _hoisted_38 = { class: "idea-text" }
const _hoisted_39 = { class: "idea-title" }
const _hoisted_40 = { style: {"display":"flex","align-items":"center","gap":"1rem"} }
const _hoisted_41 = { style: {"width":"100%","padding-top":"0.5rem","padding-bottom":"0.5rem"} }
const _hoisted_42 = ["onClick"]
const _hoisted_43 = {
  style: {"text-align":"left","display":"flex","align-items":"center","margin-bottom":"0.2rem"},
  class: "statusAndTime"
}
const _hoisted_44 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "4",
  height: "4",
  viewBox: "0 0 8 9",
  fill: "none",
  style: {"margin-left":"0.5rem"}
}
const _hoisted_45 = ["fill"]
const _hoisted_46 = { style: {"margin-left":"0.5rem"} }
const _hoisted_47 = {
  style: {"font-size":"0.875rem","text-align":"left"},
  class: "idea-description"
}
const _hoisted_48 = { class: "footer-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderBar = _resolveComponent("HeaderBar")!
  const _component_el_avatar = _resolveComponent("el-avatar")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_MoreFilled = _resolveComponent("MoreFilled")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_PostActions = _resolveComponent("PostActions")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createVNode(_component_HeaderBar, { index: '1' })
    ]),
    _createElementVNode("section", _hoisted_3, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "rectangle" }, null, -1)),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("viewProfile")), 1)
      ])
    ]),
    _createElementVNode("section", _hoisted_6, [
      _createVNode(_component_el_avatar, {
        src: 'data:image/jpeg;base64,' + $setup.userInfo?.graphUserInfo!.avatar,
        size: 120
      }, null, 8, ["src"]),
      _createElementVNode("div", _hoisted_7, _toDisplayString($setup.getUserInfo()?.graphUserInfo!.displayName
            ? $setup.getUserInfo()?.graphUserInfo!.displayName
            : $setup.getUserInfo()!.graphUserInfo!.userName), 1),
      _createElementVNode("div", _hoisted_8, _toDisplayString($setup.userInfo?.graphUserInfo!.mail), 1),
      _createVNode(_component_el_row, {
        gutter: 20,
        class: "detail-info"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.userinfo, (value, key) => {
            return (_openBlock(), _createBlock(_component_el_col, {
              span: 12,
              key: key,
              class: "info-value",
              onClick: ($event: any) => ($setup.handleColClick(key))
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_9, _toDisplayString(key), 1),
                _createElementVNode("div", _hoisted_10, _toDisplayString(value), 1)
              ]),
              _: 2
            }, 1032, ["onClick"]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    (!$setup.isIdeas)
      ? (_openBlock(), _createElementBlock("section", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t("myComments")), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.commentList, (comment, index) => {
            return (_openBlock(), _createElementBlock("div", { key: index }, [
              _createElementVNode("div", _hoisted_13, [
                (_openBlock(), _createElementBlock("svg", {
                  xmlns: "http://www.w3.org/2000/svg",
                  width: "8",
                  height: "8",
                  viewBox: "0 0 8 9",
                  fill: $setup.getStatusColor(comment.Comment.Status),
                  style: {"margin-right":"0.5rem"}
                }, [
                  _createElementVNode("circle", {
                    cx: "4",
                    cy: "4.5",
                    r: "4",
                    fill: $setup.getStatusColor(comment.Comment.Status)
                  }, null, 8, _hoisted_15)
                ], 8, _hoisted_14)),
                _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t("idea")), 1),
                _createElementVNode("span", _hoisted_17, [
                  _cache[2] || (_cache[2] = _createElementVNode("span", { class: "grey-text" }, "\" ", -1)),
                  _createTextVNode(_toDisplayString(comment.Comment.Title) + " ", 1),
                  _cache[3] || (_cache[3] = _createElementVNode("span", { class: "grey-text" }, " \"", -1))
                ])
              ]),
              _createElementVNode("div", _hoisted_18, [
                _createElementVNode("div", _hoisted_19, _toDisplayString(comment.Comment.Content), 1),
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("div", _hoisted_21, [
                    _createElementVNode("div", _hoisted_22, _toDisplayString($setup.formatTimeAgo(comment.Comment.CreatedOn)), 1),
                    _createElementVNode("button", {
                      class: "icon-button",
                      onClick: ($event: any) => (
                  comment.IsLike
                    ? $setup.CommentLikeCancel(comment.Comment.CommId)
                    : $setup.CommentLike(comment.Comment.CommId)
                )
                    }, [
                      _withDirectives(_createElementVNode("img", _hoisted_24, null, 512), [
                        [_vShow, !comment.IsLike]
                      ]),
                      _withDirectives(_createElementVNode("img", _hoisted_25, null, 512), [
                        [_vShow, comment.IsLike]
                      ]),
                      _createElementVNode("span", _hoisted_26, _toDisplayString(comment.Comment.LikeCount), 1)
                    ], 8, _hoisted_23)
                  ]),
                  _createVNode(_component_el_popover, {
                    placement: "bottom-end",
                    trigger: "hover",
                    "popper-class": "custom-popper"
                  }, {
                    reference: _withCtx(() => [
                      _createVNode(_component_el_icon, { style: {"color":"#484851","cursor":"pointer"} }, {
                        default: _withCtx(() => [
                          _createVNode(_component_MoreFilled)
                        ]),
                        _: 1
                      })
                    ]),
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_27, [
                        _createElementVNode("button", {
                          class: "custom-btn",
                          onClick: ($event: any) => ($setup.DeleteComment(comment.Comment.CommId))
                        }, _toDisplayString(_ctx.$t("delete")), 9, _hoisted_28)
                      ])
                    ]),
                    _: 2
                  }, 1024)
                ])
              ])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    ($setup.ideaList.length == 0 && $setup.isIdeas)
      ? (_openBlock(), _createElementBlock("section", _hoisted_29, [
          _createElementVNode("div", _hoisted_30, _toDisplayString(_ctx.$t("noSubmissions")), 1),
          _createVNode(_component_el_button, {
            class: "submit-btn",
            onClick: $setup.navToSubmit,
            style: {"margin-top":"1rem"}
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("submitIdea")), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ]))
      : _createCommentVNode("", true),
    ($setup.ideaList.length != 0 && $setup.isIdeas)
      ? (_openBlock(), _createElementBlock("section", _hoisted_31, [
          _createElementVNode("div", _hoisted_32, [
            _createElementVNode("div", _hoisted_33, _toDisplayString(_ctx.$t("myIdeas")), 1),
            _createVNode(_component_el_button, {
              class: "submit-btn",
              onClick: $setup.navToSubmit
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("submitIdea")), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.ideaList, (idea, index) => {
            return (_openBlock(), _createElementBlock("div", {
              style: {"display":"flex","margin-top":"1rem","width":"100%","align-items":"start","cursor":"pointer"},
              key: index,
              onClick: ($event: any) => ($setup.navToDetail(idea.Idea.IdeaId))
            }, [
              (idea.Idea.BannerImgUrl)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: idea.Idea.BannerImgUrl + '?' + $setup.getSasTokenFromCache(),
                    class: "custom-img"
                  }, null, 8, _hoisted_35))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_36, [
                _createElementVNode("div", _hoisted_37, [
                  _createElementVNode("div", null, [
                    _createElementVNode("span", _hoisted_38, _toDisplayString(_ctx.$t("idea")), 1),
                    _createElementVNode("span", _hoisted_39, [
                      _cache[4] || (_cache[4] = _createElementVNode("span", { class: "grey-text" }, "\" ", -1)),
                      _createTextVNode(_toDisplayString(idea.Idea.Title) + " ", 1),
                      _cache[5] || (_cache[5] = _createElementVNode("span", { class: "grey-text" }, "\"", -1))
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_40, [
                    _createVNode(_component_PostActions, { card: idea }, null, 8, ["card"]),
                    _createElementVNode("div", {
                      onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
                    }, [
                      _createVNode(_component_el_popover, {
                        placement: "bottom-end",
                        trigger: "hover",
                        "popper-class": "custom-popper"
                      }, {
                        reference: _withCtx(() => [
                          _createVNode(_component_el_icon, { style: {"color":"#484851","cursor":"pointer"} }, {
                            default: _withCtx(() => [
                              _createVNode(_component_MoreFilled)
                            ]),
                            _: 1
                          })
                        ]),
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_41, [
                            _createElementVNode("button", {
                              class: "custom-btn",
                              onClick: ($event: any) => ($setup.handleDeleteIdeaClick(idea.Idea.IdeaId))
                            }, _toDisplayString(_ctx.$t("delete")), 9, _hoisted_42)
                          ])
                        ]),
                        _: 2
                      }, 1024)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_43, [
                  _createElementVNode("div", {
                    style: _normalizeStyle([{"display":"flex","align-items":"center"}, `color: ${$setup.getStatusColor(idea.Idea.Status)}`])
                  }, [
                    _createTextVNode(_toDisplayString($setup.getStatusText(idea.Idea.Status)) + " ", 1),
                    (_openBlock(), _createElementBlock("svg", _hoisted_44, [
                      _createElementVNode("circle", {
                        cx: "4",
                        cy: "4.5",
                        r: "4",
                        fill: $setup.getStatusColor(idea.Idea.Status)
                      }, null, 8, _hoisted_45)
                    ]))
                  ], 4),
                  _createElementVNode("div", _hoisted_46, _toDisplayString($setup.formatTimeAgo(idea.Idea.CreatedOn)), 1)
                ]),
                _createElementVNode("div", _hoisted_47, _toDisplayString($setup.removeHtmlTags(idea.Idea.Description)), 1)
              ])
            ], 8, _hoisted_34))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("section", null, [
      _cache[6] || (_cache[6] = _createElementVNode("img", {
        src: _imports_2,
        style: {"width":"100%"}
      }, null, -1)),
      _createElementVNode("div", _hoisted_48, _toDisplayString(_ctx.$t("aboutGSKMsg")), 1)
    ])
  ]))
}