import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "9",
  height: "9",
  viewBox: "0 0 8 9",
  fill: "none"
}
const _hoisted_3 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group")!
  const _component_el_collapse_item = _resolveComponent("el-collapse-item")!
  const _component_el_collapse = _resolveComponent("el-collapse")!

  return (_openBlock(), _createBlock(_component_el_collapse, {
    modelValue: _ctx.activeNames,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.activeNames) = $event))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_collapse_item, {
        name: "Operation",
        title: _ctx.$t('operation')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_checkbox_group, {
            class: "custom-checkbox-group",
            modelValue: _ctx.checkedOperations,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checkedOperations) = $event)),
            onChange: _ctx.handleOperationChange
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.operationOptions, (operationOption) => {
                return (_openBlock(), _createBlock(_component_el_checkbox, {
                  key: operationOption.OperationName,
                  label: operationOption.OperationName
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(operationOption.OperationName), 1),
                    _createElementVNode("div", {
                      class: "checkbox-right",
                      style: _normalizeStyle({
              color: operationOption.isChecked ? '#f36633' : '#868693',
            })
                    }, [
                      _createTextVNode(_toDisplayString(operationOption.OperationCount), 1),
                      _createElementVNode("img", {
                        src: 
                operationOption.isChecked
                  ? operationOption.checkediconPath
                  : operationOption.iconPath
              ,
                        class: "icon"
                      }, null, 8, _hoisted_1)
                    ], 4)
                  ]),
                  _: 2
                }, 1032, ["label"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue", "onChange"])
        ]),
        _: 1
      }, 8, ["title"]),
      _createVNode(_component_el_collapse_item, {
        title: _ctx.$t('status'),
        name: "Status"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_checkbox_group, {
            class: "custom-checkbox-group",
            modelValue: _ctx.checkedStatus,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.checkedStatus) = $event)),
            onChange: _ctx.handleStatusChange
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.statusOptions, (statusOption, statusoption_index) => {
                return (_openBlock(), _createBlock(_component_el_checkbox, {
                  key: statusoption_index,
                  label: statusOption.StatusCode,
                  modelValue: statusOption.isChecked,
                  "onUpdate:modelValue": ($event: any) => ((statusOption.isChecked) = $event),
                  checked: statusOption.isChecked
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(statusOption.StatusName), 1),
                    _createElementVNode("div", {
                      class: "checkbox-right",
                      style: _normalizeStyle({
              color: statusOption.isChecked
                ? _ctx.getStatusColor(statusOption.StatusCode)
                : '#868693',
            })
                    }, [
                      _createTextVNode(_toDisplayString(statusOption.StatusCount) + " ", 1),
                      (_openBlock(), _createElementBlock("svg", _hoisted_2, [
                        _createElementVNode("circle", {
                          cx: "4",
                          cy: "4.5",
                          r: "4",
                          fill: _ctx.getStatusColor(statusOption.StatusCode)
                        }, null, 8, _hoisted_3)
                      ]))
                    ], 4)
                  ]),
                  _: 2
                }, 1032, ["label", "modelValue", "onUpdate:modelValue", "checked"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue", "onChange"])
        ]),
        _: 1
      }, 8, ["title"]),
      _createVNode(_component_el_collapse_item, {
        title: _ctx.$t('owner'),
        name: "Owner"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_checkbox_group, {
            class: "custom-checkbox-group",
            modelValue: _ctx.checkedOwner,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.checkedOwner) = $event)),
            onChange: _ctx.handleOwnerChange
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ownerOptions, (ownerOption) => {
                return (_openBlock(), _createBlock(_component_el_checkbox, {
                  key: ownerOption.UserId,
                  label: ownerOption.UserId,
                  modelValue: ownerOption.isChecked,
                  "onUpdate:modelValue": ($event: any) => ((ownerOption.isChecked) = $event),
                  checked: ownerOption.isChecked
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(ownerOption.UserName), 1)
                  ]),
                  _: 2
                }, 1032, ["label", "modelValue", "onUpdate:modelValue", "checked"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue", "onChange"])
        ]),
        _: 1
      }, 8, ["title"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}