import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "9",
  height: "9",
  viewBox: "0 0 8 9",
  fill: "none"
}
const _hoisted_2 = ["fill"]
const _hoisted_3 = { class: "time-filter" }
const _hoisted_4 = { class: "time-filter-text" }
const _hoisted_5 = { class: "time-filter" }
const _hoisted_6 = { class: "time-filter-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group")!
  const _component_el_collapse_item = _resolveComponent("el-collapse-item")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_collapse = _resolveComponent("el-collapse")!

  return (_openBlock(), _createBlock(_component_el_collapse, {
    modelValue: _ctx.activeNames,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.activeNames) = $event)),
    style: {"width":"100%"}
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_collapse_item, {
        title: _ctx.$t('status'),
        name: "Status"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_checkbox_group, {
            class: "custom-checkbox-group",
            modelValue: _ctx.checkedStatus,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checkedStatus) = $event)),
            onChange: _ctx.handleStatusChange
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.statusOptions, (statusOption, statusoption_index) => {
                return (_openBlock(), _createBlock(_component_el_checkbox, {
                  key: statusoption_index,
                  label: statusOption.StatusCode,
                  modelValue: statusOption.isChecked,
                  "onUpdate:modelValue": ($event: any) => ((statusOption.isChecked) = $event),
                  checked: statusOption.isChecked
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(statusOption.StatusName), 1),
                    _createElementVNode("div", {
                      class: "checkbox-right",
                      style: _normalizeStyle({
              color: statusOption.isChecked
                ? _ctx.getStatusColor(statusOption.StatusCode)
                : '#868693',
            })
                    }, [
                      _createTextVNode(_toDisplayString(statusOption.StatusCount) + " ", 1),
                      (_openBlock(), _createElementBlock("svg", _hoisted_1, [
                        _createElementVNode("circle", {
                          cx: "4",
                          cy: "4.5",
                          r: "4",
                          fill: _ctx.getStatusColor(statusOption.StatusCode)
                        }, null, 8, _hoisted_2)
                      ]))
                    ], 4)
                  ]),
                  _: 2
                }, 1032, ["label", "modelValue", "onUpdate:modelValue", "checked"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue", "onChange"])
        ]),
        _: 1
      }, 8, ["title"]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("createTime")), 1),
        _createElementVNode("span", {
          class: "time-filter-clear",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleClearCreateTime && _ctx.handleClearCreateTime(...args)))
        }, _toDisplayString(_ctx.$t("clear")), 1)
      ]),
      _createVNode(_component_el_date_picker, {
        modelValue: _ctx.createTime,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.createTime) = $event)),
        type: "daterange",
        "unlink-panels": "",
        "range-separator": "-",
        shortcuts: _ctx.shortcuts,
        "start-placeholder": "Start Time",
        "end-placeholder": "End Time",
        style: {"width":"100%","margin-bottom":"1.5rem"},
        onChange: _ctx.handleCreateTimeChange
      }, null, 8, ["modelValue", "shortcuts", "onChange"]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t("lastUpdate")), 1),
        _createElementVNode("span", {
          class: "time-filter-clear",
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleClearUpdateTime && _ctx.handleClearUpdateTime(...args)))
        }, _toDisplayString(_ctx.$t("clear")), 1)
      ]),
      _createVNode(_component_el_date_picker, {
        modelValue: _ctx.lastUpdateTime,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.lastUpdateTime) = $event)),
        type: "daterange",
        "unlink-panels": "",
        "range-separator": "-",
        shortcuts: _ctx.shortcuts,
        "start-placeholder": "Start Time",
        "end-placeholder": "End Time",
        style: {"width":"100%","margin-bottom":"1.5rem"},
        onChange: _ctx.handleLastUpdateTimeChange
      }, null, 8, ["modelValue", "shortcuts", "onChange"]),
      _createVNode(_component_el_collapse_item, {
        title: _ctx.$t('owner'),
        name: "Owner"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_checkbox_group, {
            class: "custom-checkbox-group",
            modelValue: _ctx.checkedOwner,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.checkedOwner) = $event)),
            onChange: _ctx.handleOwnerChange
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ownerOptions, (ownerOption) => {
                return (_openBlock(), _createBlock(_component_el_checkbox, {
                  key: ownerOption.UserId,
                  label: ownerOption.UserId,
                  modelValue: ownerOption.isChecked,
                  "onUpdate:modelValue": ($event: any) => ((ownerOption.isChecked) = $event),
                  checked: ownerOption.isChecked
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(ownerOption.UserName), 1)
                  ]),
                  _: 2
                }, 1032, ["label", "modelValue", "onUpdate:modelValue", "checked"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue", "onChange"])
        ]),
        _: 1
      }, 8, ["title"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}